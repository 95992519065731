import React, { useEffect } from 'react';
import Editor from '@monaco-editor/react';
import Box from '@mui/material/Box';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';
import Flex from '../../../components/base/Flex';
import OutlinedButton from '../../../components/base/OutlinedButton';
import ContainedButton from '../../../components/base/ContainedButton';
import CustomModal from '../../../components/base/CustomModal';
import { ColorContext } from '../../../AppTheme';
import { toast } from 'react-toastify';

const CustomJavascriptStep = ({
  open,
  onClose,
  onComplete,
  addJavascriptStep,
  editMode = false,
  onHandleUpdate,
  initialValue
}) => {
  const { mode } = React.useContext(ColorContext);
  const [stepTitle, setStepTitle] = React.useState('Execute javascript step');
  const [code, setCode] = React.useState(initialValue || '');

  React.useEffect(() => {
    if (editMode) {
      setCode(initialValue || '');
    }
  }, [editMode, initialValue]);

  const editorOptions = {
    fontSize: 12,
    formatOnPaste: true,
    renderIndentGuides: true,
    formatOnType: true,
    inlineSuggest: true,
    autoClosingBrackets: true,
    wordWrap: 'on',
    minimap: {
      enabled: false
    }
  };

  const handleCloseModal = () => {
    onClose();
    setCode('');
  };

  const handleSaveCustomExpression = () => {
    if (!stepTitle) {
      toast.error('Please provide a title before proceeding');
      return;
    }

    if (!code) {
      toast.error('Please provide a javascript expression before proceeding');
      return;
    }

    editMode ? onHandleUpdate(code) : addJavascriptStep(stepTitle, code);

    onComplete();
    setCode('');
  };

  return (
    <CustomModal open={open}>
      <Box py={2} px={2} width={'80vw'} maxWidth={'550px'}>
        <Typography
          as={'h4'}
          color="primary"
          sx={{
            fontSize: '1.4rem',
            mb: 3
          }}>
          {editMode ? 'Edit Javascript Expression' : 'Add new Javascript step'}
        </Typography>

        {!editMode && (
          <TextField
            id={'custom-js-step-title'}
            variant={'outlined'}
            label={'Step title'}
            fullWidth
            value={stepTitle}
            onChange={(e) => setStepTitle(e.target.value)}
            placeholder={'Search for a test case or folder by name'}
            size={'small'}
            sx={{ mb: 1 }}
          />
        )}

        <Editor
          height={'350px'}
          theme={mode === 'dark' ? 'vs-dark' : 'blackboard'}
          value={code}
          onChange={(value, event) => setCode(value)}
          language={'javascript'}
          options={editorOptions}
        />

        <Flex sx={{ py: 2 }} columnGap={2} justifyContent={'flex-end'}>
          <OutlinedButton onClick={handleCloseModal}>Close</OutlinedButton>
          <ContainedButton color={'primary'} onClick={handleSaveCustomExpression} autoFocus>
            {editMode ? 'Update' : 'Add'}
          </ContainedButton>
        </Flex>
      </Box>
    </CustomModal>
  );
};

export default CustomJavascriptStep;
